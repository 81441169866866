.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
  width: 100vw;
  background-image: url("../img/bg_version2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;
}

.nav {
  height: 50px;
  width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    color: white;
    letter-spacing: 2px;
    opacity: 0.85;
    font-weight: 300;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &__links {
    display: flex;
    gap: 30px;

    a {
      letter-spacing: 2px;
      opacity: 0.85;
      font-weight: 300;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.intro-text {
  max-width: 850px;
  height: 200px;
  padding: 20px;
  border-radius: 13px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      color: white;
    }
  }

  &__title {
    font-size: 25px;
  }

  &__paragraph {
    font-size: 18px;
    word-break: break-word;
    max-width: 100vw;
  }

  &__divide-line {
    width: 150px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
    height: 6px;
    margin: 30px 0;
  }
}
@media only screen and (max-width: 600px) {
  .header {
    padding: 50px 6%;
  }

  .nav {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    height: initial;
  }

  .nav__links {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1300px) {
  .nav {
    background-color: #000000ed;
    border-radius: 10px;
    padding: 15px 5%;
  }

  .intro-text {
    height: initial;
    background-color: #000000cc;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .header {
    background-position: center;
  }
}