* {
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  box-sizing: border-box;
}

body,
html {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 21px;
  font-weight: 400;
}