.aboutus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;

  &__intro {
    max-width: 800px;
    padding: 20px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: black;
    margin: 30px 0;

    &-headline {
      font-size: 25px;
      font-weight: 600;
      color: #305fe8;
    }

    &-divide-line {
      width: 150px;
      height: 10px;
      background-color: #305fe8;
      border-radius: 5px;
      height: 6px;
      margin: 30px 0;
    }

    &-desc {
      font-size: 18px;
      word-break: break-word;
      text-align: justify;

      div {
        margin: 15px 0;
      }
    }
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    width: 70%;
    align-items: baseline;
    margin: 40px 0;
  }

  &__service-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    flex: 1 1 33%;
    gap: 15px;

    &-title {
      font-size: 17px;
      text-transform: uppercase;
      color: black;
      font-weight: 600;
      text-align: center;
    }

    &-desc {
      font-size: 15px;
      text-align: center;
      line-height: 1.4em;
    }

    &-icon {
      font-size: 40px !important;
      color: #305fe8;
    }

    &-hours {
      display: flex;
      width: 80px;
      margin-right: 5px;
    }
  }
}
@media only screen and (max-width: 1348px) {
  .aboutus__service-item {
    padding: 10px;
  }
}
@media only screen and (max-width: 1076px) {
  .aboutus__services {
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin: 0;
  }
}