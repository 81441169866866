.photo-section {
  display: flex;
  flex-direction: row;
  margin: 80px 0;
  background-color: black;
  width: 100%;

  &__image {
    width: 50%;
    height: 650px;

    &-dioptic {
      background: linear-gradient(0deg, rgba(0, 49, 150, 0.1), rgba(0, 31, 150, 0.2)), url("../img/articles/diopticglasses.jpg");
      background-size: cover;
      background-position: center;
    }

    &-sunglasses {
      background-image: linear-gradient(0deg, rgba(0, 49, 150, 0.1), rgba(0, 31, 150, 0.2)), url("../img/articles/sunglasses.jpg");
      background-size: cover;
      background-position: center;
    }
  }
}
@media only screen and (max-width: 768px) {
  .photo-section__image-sunglasses {
    display: none;
  }

  .photo-section__image-dioptic {
    width: 100%;
  }
}