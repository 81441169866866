.contact {
  display: flex;
  height: calc(100vh - 200px);

  &__info {
    position: absolute;
    left: 100px;
    top: 250px;
    width: 550px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 15px;
    background-color: #1F2937;
    border-radius: 8px;
  }

  &__info-item {
    display: flex;
    flex-direction: column;
    flex: 1 1 33%;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__info-title {
    color: white;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.85;
  }

  &__info-description {
    color: white;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.85;
  }

  &__info-icon {
    width: fit-content;
    color: white;
    border: 2px solid white;
    opacity: 0.85;
    font-size: 50px;
    border-radius: 50%;
    padding: 10px;
  }

  &__info-social {
    margin: 10px 0;

    a {
      margin: 0 5px;
    }
  }

  &__map {
    width: 100%;

    > * {
      width: 100%;
      height: 100%;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .contact {
    flex-direction: column;
  }

  .contact__info {
    position: static;
    height: initial;
    width: 100%;
    border-radius: 0;
  }

  .contact__map {
    height: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .contact__info-item {
    flex: 1 1 100%;
  }
}