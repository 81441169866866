.gallery {
  max-width: 1000px;
  margin: 5% auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.4);

  &__container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;

    .box {
      border-radius: 10px;

      a {
        img {
          width: 400px;
        }
      }
    }
  }
}