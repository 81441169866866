.services {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 70vw;
  }
}

.service {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 60px;
    flex: 1 1 40%;
    gap: 15px;
  }

  &__title {
    font-size: 17px;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    text-align: center;
  }

  &__description {
    font-size: 15px;
    text-align: justify;
    line-height: 1.4em;
  }

  &__icon {
    font-size: 40px !important;
    color: #305fe8;
  }
}
@media only screen and (max-width: 1150px) {
  .service {
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .services__container {
    width: initial;
  }
}
@media only screen and (max-width: 425px) {
  .service__item {
    padding: 10px;
  }
}