.brands__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 70vw;
  margin: 50px 0;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  margin: 80px 0;
}

.brand {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7a7a7a0f;
  flex: 1 1 220px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;

  &:hover &__image {
    opacity: 1;
    transition: opacity 0.6s linear;
  }

  &:hover &__title {
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  &__title {
    font-size: 22px;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    text-align: center;
  }

  &__image {
    opacity: 0;
    position: absolute;
    width: 160px;
  }
}
@media only screen and (max-width: 425px) {
  .brands__container {
    width: initial;
  }
}