.articles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  margin: 80px 0;
  flex-wrap: wrap;
}

.articles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 40px;
}

.head-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 0 22%;
  gap: 10px;

  &__image {
    width: 300px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__desc {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4em;
  }
}
@media only screen and (max-width: 1360px) {
  .head-article {
    flex: 0 0 33%;
  }
}