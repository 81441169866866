.footer {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #1F2937;
  color: white;
  padding: 50px 8%;

  &__description {
    width: 400px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.45em;
    text-align: justify;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-icon {
      font-size: 30px !important;
      color: white;
    }
  }

  &__contact-item {
    display: flex;
    align-items: center;

    a {
      text-transform: initial;
    }

    span {
      margin: 0 5px;
    }
  }

  &__contact-item-icon {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 20px;
  }

  .footer__description {
    text-align: center;
  }

  .footer__contact-item {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;

    span {
      margin: 0;
    }
  }
}
@media only screen and (max-width: 425px) {
  .footer__description {
    word-break: keep-all;
    width: 320px;
  }
}