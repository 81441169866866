@import "base.scss";
@import "header.scss";
@import "articles.scss";
@import "photo-section.scss";
@import "brands.scss";
@import "services.scss";
@import "footer.scss";
@import "contact/contact.scss";
@import "gallery/gallery.scss";
@import "gallery/swipebox.scss";
@import "about-us/about-us.scss";

.page-content {
  position: relative;
}

.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-other {
  .nav {
    background-color: #131922;
    padding: 25px 5%;
    height: initial;
    border-radius: 0;
  }
}

.section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;

  &__underline {
    height: 3px;
    width: 50px;
    background-color: #305fe8;
    border-radius: 5px;
    margin-top: 6px;
  }
}